<template>
    <div class="my-5 border-t border-black">
        <div class="my-5 text-medium-700 md:!text-xl">{{ title }}</div>
        <NTheme>
            <NSwitch
                :value="value"
                @update:value="$emit('update:toggle', $event)"
            />
        </NTheme>
    </div>
</template>

<script lang="ts" setup>
import { NSwitch } from "naive-ui";
import NTheme from "../../common/NTheme.vue";

const props = defineProps<{
    title: string;
    value: boolean;
}>();
</script>
