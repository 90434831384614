const oua = "oua";
import { CONFIG_OPTIONS as config } from "@src/globals";

export function getOuaIDs(
    dataGovs: {
        origin: {
            sourceId: string;
            provider: {
                identifier: string;
            };
        }[];
    }[],
): string[] {
    if (!dataGovs) {
        return;
    }

    const _dataGovs = Array.isArray(dataGovs) ? dataGovs : [dataGovs];
    const ouaIDs = [];

    _dataGovs.forEach((dg) => {
        const ouaOrigin = dg.origin.find((o) => o.provider?.identifier === oua);
        const ouaID = ouaOrigin?.sourceId;

        if (ouaID) {
            ouaIDs.push(ouaID);
        }
    });

    return ouaIDs;
}

/**
 * Outdoor Active Tracking function
 * @param type "detail" or "teaser"
 * @param ouaIDs The Source IDs of the Outdoor Active data to track
 * @returns true if the tracking was successful, false otherwise
 */
export function track(type: "detail" | "teaser", ouaIDs: string[]): boolean {
    if (
        typeof window === "undefined" || // SSR (no window object available)
        config.liveHost !== window.location.hostname || // only track on live domain
        !ouaIDs.length // no IDs to track
    ) {
        return false;
    }

    window["oacrr"].track(type, ouaIDs);
    return true;
}
