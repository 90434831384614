<template>
    <div
        class="flex flex-row s:flex-col flex-wrap gap-x-8 gap-y-12 content:gap-x-4"
    >
        <TourCard
            v-for="tour in data.values"
            class="tour-card-container"
            :tour="tour as any"
        />
    </div>
</template>

<style lang="scss" scoped>
.tour-card-container {
    @apply flex-grow;
    @apply mc:w-5/24 mc:max-w-[calc((100%-3*2rem)/4)];
    @apply content:max-w-[calc((100%-3rem)/4)];
    @apply m:w-11/24 m:max-w-[calc((100%-1rem)/2)];
    @apply s:w-full s:max-w-full;
}
</style>

<script lang="ts" setup>
import { PropType, watch } from "vue";
import { FilterResponse } from "@src/pages/api/filter";
import TourCard from "../common/TourCard.vue";
import { getOuaIDs, track } from "@src/helpers/outdoorActiveTracking.helper";

const props = defineProps({
    data: Object as PropType<FilterResponse>,
});

// Outdoor Active Tracking
watch(
    () => props.data,
    (data) => {
        const ouaIDs = getOuaIDs(
            data.values.map((t) => t.dataGovernance as any),
        );
        track("teaser", ouaIDs);
    },
    { immediate: true },
);
</script>
