<template>
    <div class="my-5 border-t border-black">
        <div class="my-5 text-medium-700 md:!text-xl">{{ title }}</div>
        <div class="grid grid-cols-3 gap-2">
            <a
                v-for="(month, index) in months"
                class="!cursor-pointer select-none"
                :class="`btn-${month.enabled ? 'primary' : 'light'}-no-hover`"
                @click="$emit('month-selected', index)"
            >
                {{ month.label }}
            </a>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { NButton } from "naive-ui";

const props = defineProps<{
    title: string;
    months: { label: string; enabled: boolean }[];
}>();
</script>
