<template>
    <div
        class="flex flex-row m:flex-col gap-x-[clamp(1rem,2vw,3rem)] gap-y-8"
        :class="[
            {
                'flex-wrap minM:gap-y-16': !isStaggeredLayout,
            },
            {
                'custom-aspect-ratio': CONFIG_OPTIONS.portfolio.layout === 3,
            },
        ]"
    >
        <div
            v-if="isStaggeredLayout"
            v-for="column in divideArrayIntoColumns(data.values)"
            class="flex flex-grow flex-col gap-y-8 w-7/24"
        >
            <PortfolioCard v-for="portfolio in column" :portfolio="portfolio" />
        </div>
        <PortfolioCard
            v-if="!isStaggeredLayout"
            v-for="portfolio in data.values"
            class="non-staggered-portfolio-card flex-grow w-7/24 m:w-full"
            :portfolio="portfolio"
        />
    </div>
</template>

<style lang="scss">
.non-staggered-portfolio-card {
    --gap-x: clamp(1rem, 2vw, 3rem);
    @screen minM {
        @apply max-w-[calc((100%-2*var(--gap-x))/3)];
    }
}
.custom-aspect-ratio {
    @screen m {
        > :nth-child(odd) {
            img {
                aspect-ratio: 3/2;
            }
        }
        > :nth-child(even) {
            img {
                aspect-ratio: 1/1;
            }
        }
    }
    @screen minM {
        > :nth-child(odd) {
            > :first-child img {
                aspect-ratio: 3/2;
            }
            > :nth-child(2n + 2) img {
                aspect-ratio: 3/2;
            }
            > :nth-child(2n + 3) img {
                aspect-ratio: 1/1;
            }
        }
        > :nth-child(even) {
            > :nth-child(odd) img {
                aspect-ratio: 1/1;
            }
            > :nth-child(even) img {
                aspect-ratio: 3/2;
            }
        }
    }
}
</style>

<script lang="ts" setup>
import { PropType, ref, watch } from "vue";
import { FilterResponse } from "@src/pages/api/filter";
import PortfolioCard from "../common/PortfolioCard.vue";
import { CONFIG_OPTIONS } from "@src/globals";
import { getOuaIDs, track } from "@src/helpers/outdoorActiveTracking.helper";

const props = defineProps({
    data: Object as PropType<FilterResponse>,
});

// Outdoor Active Tracking
watch(
    () => props.data,
    (data) => {
        const ouaIDs = getOuaIDs(
            data.values.map((p) => p.dataGovernance as any),
        );
        track("teaser", ouaIDs);
    },
    { immediate: true },
);

const isStaggeredLayout = ref<boolean>();
setupResizeEventListener();

function setupResizeEventListener() {
    isStaggeredLayout.value = getStaggeredLayout();
    window.addEventListener("resize", () => {
        isStaggeredLayout.value = getStaggeredLayout();
    });
}

function getStaggeredLayout() {
    const mediumWidth = 993; // 993px is the breakpoint for medium screens
    const staggeredLayoutNumber = 3; // layout 3 is used for the staggered style

    return (
        window.innerWidth >= mediumWidth &&
        CONFIG_OPTIONS.portfolio.layout === staggeredLayoutNumber
    );
}

/**
 * Divides an array into `n` (default 3) arrays of equal length or as close as possible.
 */
const divideArrayIntoColumns = (array: any[], nColumns = 3) => {
    // if the array is undefined, return an empty array (this is true until the data is fetched)
    if (!array) return [];
    const columns = Array.from({ length: nColumns }, () => []);
    array.forEach((portfolio, i) => {
        columns[i % nColumns].push(portfolio);
    });
    return columns;
};
</script>
