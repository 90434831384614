<template>
    <div class="mb-5 border-t border-black">
        <div class="my-5 text-medium-700 md:!text-xl">{{ title }}</div>
        <NTheme>
            <NSlider
                class="range-slider"
                v-model:value="value"
                range
                :step="step"
                :min="min"
                :max="max"
                :format-tooltip="(x) => (unit ? `${x}${unit}` : x)"
                @update:value="validateValue"
                @dragend="emitValueUpdate"
            />
        </NTheme>
        <div class="flex justify-between mt-4" v-if="showButtons">
            <NTheme>
                <NInputNumber
                    v-model:value="value[0]"
                    :step="step"
                    :min="min"
                    :max="value[1] - 1"
                    class="range-input w-24 text-center"
                    button-placement="both"
                    @update:value="emitValueUpdate"
                />
            </NTheme>
            <NTheme>
                <NInputNumber
                    v-model:value="value[1]"
                    :step="step"
                    :min="value[0] + 1"
                    :max="max"
                    class="range-input w-24 text-center"
                    button-placement="both"
                    @update:value="emitValueUpdate"
                />
            </NTheme>
        </div>
        <div v-else>
            <div class="flex justify-between mt-4 px-1">
                <div class="text-regular" v-if="minLabel" v-html="minLabel" />
                <div class="text-regular" v-else>{{ min }}{{ unit }}</div>
                <div class="text-regular" v-if="maxLabel" v-html="maxLabel" />
                <div class="text-regular" v-else>{{ max }}{{ unit }}</div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.range-slider .n-slider-handle {
    border: 2px solid black;
}
.range-input .n-input {
    --n-border-hover: 1px solid var(--color-primary) !important;
    --n-border-focus: 1px solid var(--color-primary) !important;
    .n-button {
        --n-text-color-hover: var(--color-primary) !important;
        --n-text-color-focus: var(--color-primary) !important;
    }
}
</style>

<script lang="ts" setup>
import { ref } from "vue";
import { NSlider, NInputNumber } from "naive-ui";
import { watch } from "vue";
import NTheme from "../../common/NTheme.vue";

const props = defineProps<{
    title: string;
    min: number;
    max: number;
    lowValue: number;
    highValue: number;
    unit?: string;
    step?: number;
    showButtons?: boolean;
    minLabel?: string;
    maxLabel?: string;
}>();

const emit = defineEmits(["update:range-value"]);
const value = ref([props.lowValue, props.highValue]);

let prevValue = null;
function validateValue() {
    if (value.value[0] >= value.value[1]) {
        value.value[0] = prevValue[0];
        value.value[1] = prevValue[1];
    }
    prevValue = value.value;
}

function emitValueUpdate() {
    emit("update:range-value", [value.value[0], value.value[1]]);
}

watch(
    () => props.lowValue,
    (newValue) => (value.value[0] = newValue),
);
watch(
    () => props.highValue,
    (newValue) => (value.value[1] = newValue),
);
</script>
