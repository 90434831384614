<template>
    <div class="my-5 border-t border-black">
        <div class="my-5 text-medium-700 md:!text-xl">{{ title }}</div>
        <div v-for="(item, index) in items" class="my-2">
            <input
                class="cursor-pointer"
                type="radio"
                :name="name || title"
                :id="`${name}-${index}`"
                :value="item.value"
                :checked="index === selectedIndex"
                @change="$emit(`update:radio`, item.value)"
            />
            <label
                class="pl-2 align-middle text-regular-400 cursor-pointer"
                :for="`${name}-${index}`"
            >
                {{ item.label }}
            </label>
        </div>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
    title: string;
    name: string;
    items: { value: string; label: string }[];
    selectedIndex: number;
}>();
</script>
