<template>
    <!-- Filter & Sort buttons -->
    <div
        class="flex gap-4 mb-6 mt-12 md:mt-20 fade-in"
        :class="
            overviewComponents[type]?.filterable
                ? 'justify-between'
                : 'justify-end'
        "
    >
        <a
            v-if="overviewComponents[type]?.filterable"
            class="btn-primary h-min s:!px-3 s:!py-[11px]"
            @click="toggleFilters()"
        >
            <i
                class="icon md:mr-2"
                :class="['fa-bars-filter', config.global.iconStyle]"
            />
            <span class="hidden md:block">{{ t("filters") }}</span>
        </a>
        <div class="flex overflow-x-auto gap-2 self-center">
            <div class="flex overflow-x-auto gap-2">
                <Tag
                    v-for="{
                        sortName,
                        sortValue,
                        sortIcon,
                    } in overviewComponents[type]?.sortOptions.filter(
                        (o) => o.sortValue, // filter out empty sortValues. These sortValues were planned but so far not implemented
                    )"
                    @click="updateOrderBy(sortValue)"
                    class="select-none"
                    :class="sortValue ? 'cursor-pointer' : 'cursor-default'"
                    :text="sortName"
                    :icon-name="sortIcon"
                    :variant="sortValue ? 'sorting' : 'sortingDisabled'"
                    :color="sortValue ? 'var(--text-secondary)' : '#000'"
                />
            </div>
            <i
                class="fa-arrow-down-arrow-up px-2 leading-7 text-secondary"
                :class="config.global.iconStyle"
            />
        </div>
    </div>

    <div class="flex gap-4 justify-end mb-8 text-sm">
        <span class="content-center"
            ><b>{{ data.count }}</b> {{ t("results") }}</span
        >
    </div>

    <div class="relative">
        <!-- Loading spinner -->
        <div
            v-if="isDataLoading"
            class="loading loading_color-primary loading-lg mx-auto w-min content-center"
            :style="`height: ${getComponentHeightPx()}px`"
        ></div>

        <!-- Overview component -->
        <component
            v-if="type in overviewComponents && data.values"
            :is="overviewComponents[type].component"
            :data="data"
            :config="config"
            :id="`overview-component-${type}`"
            :class="[
                { 'opacity-25 absolute inset-0': isDataLoading },
                'transition-opacity duration-300',
            ]"
        ></component>

        <!-- No data message -->
        <div
            class="text-medium-400 text-center bg-secondary"
            :class="[
                { 'rounded-2xl': config.global.roundedCorners },
                !isDataLoading && data.count === 0 ? 'block' : 'inline',
                !isDataLoading && data.count === 0
                    ? 'opacity-100'
                    : 'opacity-0',
                'transition-opacity duration-300',
                'my-12 py-8 md:py-12 md:mx-side s:px-side',
            ]"
        >
            {{ t("noDataForSelectedCriteria") }}
        </div>
    </div>

    <!-- Pagination -->
    <NTheme>
        <NPagination
            class="justify-center pt-12 max-w-full fade-in"
            v-if="!infiniteScrollEnabled && data.values"
            v-model:page="page"
            v-model:page-size="pageSize"
            show-size-picker
            :page-count="pageCount"
            :page-sizes="[getDefaultPageSize(type), 12, 24]"
            :on-update-page="() => filterAndRemoveNonAvailableFacets(200)"
            :on-update-page-size="updatePageSize"
            :page-slot="getPageSlots()"
        />
    </NTheme>

    <!-- Load more button -->
    <div v-if="infiniteScrollEnabled" class="flex justify-center pt-12">
        <a class="btn-primary s:w-full" @click="loadMore()">
            <span>{{ t("loadMore") }}</span>
        </a>
    </div>

    <!-- Filters background -->
    <div
        class="fixed z-50 top-0 left-0 bottom-0 right-0 bg-black transition-opacity duration-300"
        :class="showFilters ? 'opacity-50' : 'opacity-0 pointer-events-none'"
        @click="toggleFilters()"
    ></div>

    <!-- Filters sidepanel -->
    <div
        class="fixed z-50 top-0 left-0 bottom-0 xm:right-0 pointer-events-none"
    >
        <div
            class="filters-sidepanel relative bg-white overflow-auto pointer-events-auto"
            :class="[
                'h-full min-w-[400px] md:min-w-[500px] xm:min-w-fit md:max-w-lg',
                'px-8 md:px-12 pt-12 md:pt-16 pb-12',
                'transition-transform duration-300',
                showFilters ? 'translate-x-0' : 'translate-x-[-100%]',
            ]"
            @click="$event.stopPropagation()"
        >
            <span
                class="absolute top-6 right-6 cursor-pointer"
                @click="toggleFilters()"
            >
                <i class="fa-xmark" :class="config.global.iconStyle" />
            </span>
            <div class="text-f4 mb-5">{{ title || type }}</div>

            <!-- Event Filters -->
            <div v-if="type === 'Event'">
                <Toggle
                    v-if="accessibilityApiWorks"
                    :title="t('barrierFree')"
                    :value="accessibilityFilter"
                    @update:toggle="
                        accessibilityFilter = $event;
                        updateFilters();
                    "
                />
                <DatePicker
                    :title="t('date')"
                    :dateRange="dateRange"
                    @update:dateRange="
                        dateRange = $event;
                        updateFilters();
                    "
                />
                <Toggle
                    v-if="topEntryTags?.length > 0"
                    :title="t('topEvents')"
                    :value="topEntryToggle"
                    @update:toggle="
                        topEntryToggle = $event;
                        updateFilters();
                    "
                />
                <Checkbox
                    v-for="filter in data.filters"
                    :title="filter.name"
                    :items="filter.facets"
                    :active-facets="activeFacets[filter.filterPropertyName]"
                    @update:checked-keys="updateCheckedKeys"
                />
            </div>

            <!-- Webcam Filter -->
            <div v-else-if="type === 'Webcam'">
                <Checkbox
                    v-for="filter in data.filters"
                    :title="filter.name"
                    :items="filter.facets"
                    :active-facets="activeFacets[filter.filterPropertyName]"
                    @update:checked-keys="updateCheckedKeys"
                />
            </div>

            <!-- Tour Filters -->
            <div v-else-if="type === 'Tour'">
                <Toggle
                    v-if="accessibilityApiWorks"
                    :title="t('barrierFree')"
                    :value="accessibilityFilter"
                    @update:toggle="
                        accessibilityFilter = $event;
                        updateFilters();
                    "
                />
                <Radio
                    :title="t('status')"
                    name="TourStateRadio"
                    :items="statusItems"
                    :selectedIndex="selectedStatusIndex"
                    @update:radio="updateStatusRadio($event)"
                />
                <Toggle
                    v-if="topEntryTags?.length > 0"
                    :title="t('topTours')"
                    :value="topEntryToggle"
                    @update:toggle="
                        topEntryToggle = $event;
                        updateFilters();
                    "
                />
                <Checkbox
                    v-for="filter in data.filters"
                    :title="filter.name"
                    :items="filter.facets"
                    :active-facets="activeFacets[filter.filterPropertyName]"
                    @update:checked-keys="updateCheckedKeys"
                />
                <Range
                    :title="t('distance')"
                    :min="tourLengthRangeMin"
                    :max="tourLengthRangeMax"
                    :lowValue="tourLengthRange[0]"
                    :highValue="tourLengthRange[1]"
                    unit="km"
                    :step="5"
                    showButtons
                    @update:range-value="
                        tourLengthRange = $event;
                        updateFilters();
                    "
                />
                <Range
                    :title="t('duration')"
                    :min="tourDurationRangeMin"
                    :max="tourDurationRangeMax"
                    :lowValue="tourDurationRange[0]"
                    :highValue="tourDurationRange[1]"
                    unit="h"
                    :step="0.5"
                    showButtons
                    @update:range-value="
                        tourDurationRange = $event;
                        updateFilters();
                    "
                />
                <Range
                    :title="t('ascent')"
                    :min="tourAscentRangeMin"
                    :max="tourAscentRangeMax"
                    :lowValue="tourAscentRange[0]"
                    :highValue="tourAscentRange[1]"
                    unit="m"
                    :step="100"
                    showButtons
                    @update:range-value="
                        tourAscentRange = $event;
                        updateFilters();
                    "
                />
                <BestMonths
                    :title="t('bestSeason')"
                    :months="bestMonths"
                    @month-selected="updateBestMonths"
                />
            </div>

            <!-- Portfolio Filters -->
            <div v-else-if="type === 'Portfolio'">
                <Toggle
                    v-if="accessibilityApiWorks"
                    :title="t('barrierFree')"
                    :value="accessibilityFilter"
                    @update:toggle="
                        accessibilityFilter = $event;
                        updateFilters();
                    "
                />
                <Radio
                    :title="t('status')"
                    name="PortfolioStateRadio"
                    :items="statusItems"
                    :selectedIndex="selectedStatusIndex"
                    @update:radio="updateStatusRadio($event)"
                />
                <Checkbox
                    v-for="filter in data.filters"
                    :title="filter.name"
                    :items="filter.facets"
                    :active-facets="activeFacets[filter.filterPropertyName]"
                    @update:checked-keys="updateCheckedKeys"
                />
                <!-- Disabled because customers changed their mind
                <Range
                    v-if="searchTypes?.includes('FoodEstablishment')"
                    title="Preissegment"
                    :min="foodPriceRangeMin"
                    :max="foodPriceRangeMax"
                    :lowValue="foodPriceRange[0]"
                    :highValue="foodPriceRange[1]"
                    :step="1"
                    :min-label="
                        `<i class='fa-dollar text-small ` +
                        config.global.iconStyle +
                        `'></i>`
                    "
                    :max-label="
                        (
                            `<i class='fa-dollar text-small mx-1 ` +
                            config.global.iconStyle +
                            `'></i>`
                        ).repeat(5)
                    "
                    @update:range-value="
                        foodPriceRange = $event;
                        filterAndRemoveNonAvailableFacets();
                    "
                />-->
                <!-- Disabled because customers changed their mind
                <Range
                    v-if="searchTypes?.includes('LodgingBusiness')"
                    title="Sterne"
                    :min="lodgingStarsRangeMin"
                    :max="lodgingStarsRangeMax"
                    :lowValue="lodgingStarsRange[0]"
                    :highValue="lodgingStarsRange[1]"
                    :step="1"
                    :min-label="
                        `<i class='fa-star text-small ` +
                        config.global.iconStyle +
                        `'></i>`
                    "
                    :max-label="
                        (
                            `<i class='fa-star text-small mx-0.5 ` +
                            config.global.iconStyle +
                            `'></i>`
                        ).repeat(5)
                    "
                    @update:range-value="
                        lodgingStarsRange = $event;
                        filterAndRemoveNonAvailableFacets();
                    "
                />-->
            </div>
            <div v-else>
                <div class="text-regular pt-8">{{ t("noFiltersFound") }}</div>
            </div>
            <div class="flex flex-col gap-2 mt-6 w-full">
                <a class="btn-light h-10" @click="resetFilters()">
                    {{ t("resetFilters") }}
                </a>
                <a
                    class="btn-primary h-10"
                    @click="
                        filterAndRemoveNonAvailableFacets();
                        toggleFilters();
                    "
                    ><div v-if="!areFiltersLoading">
                        {{
                            t("showResults", {
                                count: (
                                    preFilterCount ?? data.count
                                )?.toString(),
                            })
                        }}
                    </div>
                    <div
                        class="loading loading_color-text-primary loading-md"
                        v-if="areFiltersLoading"
                    ></div
                ></a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.filters-sidepanel {
    &::-webkit-scrollbar,
    ::-webkit-scrollbar {
        display: none;
    }
}
</style>

<script lang="ts" setup>
import axios from "redaxios";
import { onMounted, ref } from "vue";
import { TreeOption, NPagination } from "naive-ui";
import { DetailPageType, EntryInterface } from "@gql-types/types.generated";
import { FilterResponse } from "@src/pages/api/filter";
import Events from "@src/components/overviews/Events.vue";
import Tours from "@src/components/overviews/Tours.vue";
import Portfolios from "@src/components/overviews/Portfolios.vue";
import Webcams from "@src/components/overviews/Webcams.vue";
import { CONFIG_OPTIONS as config, t } from "@src/globals";
import Tag from "@src/components/common/Tag.vue";
import { getLocaleFromUrl } from "@src/i18n/utils";
import DatePicker from "@src/components/common/filterItems/FilterDatePicker.vue";
import Toggle from "@src/components/common/filterItems/FilterToggle.vue";
import Radio from "@src/components/common/filterItems/FilterRadio.vue";
import Checkbox from "@src/components/common/filterItems/FilterCheckbox.vue";
import Range from "@src/components/common/filterItems/FilterRange.vue";
import BestMonths from "@src/components/common/filterItems/FilterBestMonths.vue";
import NTheme from "./common/NTheme.vue";

const data = ref({} as FilterResponse);
const props = defineProps<{
    filters: string;
    type: DetailPageType | string;
    title: string;
    topEntryTags?: EntryInterface[];
    searchTypes?: string[];
}>();

onMounted(() => {
    if (props.type === "Event") updateOrderBy("nextOccurrence", "asc");
    resetFilters();
});

const locale = getLocaleFromUrl(new URL(window.location.href));
const accessibilityApiWorks = false;

const showFilters = ref(false);
const activeFacets = ref({} as Record<string, Array<string>>);
const preFilterCount = ref(data.value.count);

const isDataLoading = ref(true);
const areFiltersLoading = ref(false);

const thirtyOneDaysInMs = 1000 * 60 * 60 * 24 * 31;
const dateRange = ref<[number, number]>([
    Date.now(),
    Date.now() + thirtyOneDaysInMs,
]);
const accessibilityFilter = ref(false);
const topEntryToggle = ref(false);
const statusItems = [
    { label: t("all"), value: "all" },
    { label: t("open"), value: "Open" },
    { label: t("closed"), value: "Closed" },
];
const selectedStatusIndex = ref(0);
const statusFilter = ref(statusItems[selectedStatusIndex.value].value);

// Tour Range Filters
const tourLengthRangeMin = 0;
const tourLengthRangeMax = 150;
const tourLengthRange = ref<[number, number]>([
    tourLengthRangeMin,
    tourLengthRangeMax,
]);
const tourDurationRangeMin = 0;
const tourDurationRangeMax = 24;
const tourDurationRange = ref<[number, number]>([
    tourDurationRangeMin,
    tourDurationRangeMax,
]);
const tourAscentRangeMin = 0;
const tourAscentRangeMax = 2400;
const tourAscentRange = ref<[number, number]>([
    tourAscentRangeMin,
    tourAscentRangeMax,
]);

const bestMonths = ref<
    {
        label: string;
        value: string;
        enabled: boolean;
    }[]
>([]);

// Portfolio Range Filters
// Disabled bc customer changed their mind :<
// const foodPriceRangeMin = 1;
// const foodPriceRangeMax = 5;
// const foodPriceRange = ref<[number, number]>([
//     foodPriceRangeMin,
//     foodPriceRangeMax,
// ]);
// const lodgingStarsRangeMin = 1;
// const lodgingStarsRangeMax = 5;
// const lodgingStarsRange = ref<[number, number]>([
//     lodgingStarsRangeMin,
//     lodgingStarsRangeMax,
// ]);

const orderBy = ref("");
type sortOption = {
    name: string;
    sortValue: string;
    sortIcon: "fa-sort-up" | "fa-sort-down" | "";
};
type sortOrder = "asc" | "desc" | "";

const overviewComponents = {
    /** Register vue components here.
     *  props.type is used to determine which component to render.
     *  searchType is used for the api call, if props.type doesn't
     *  correspond to the types that should be searched */
    Event: {
        /** Vue component */
        component: Events,
        filterable: true,
        defaultPageSize: 4,
        sortOptions: [
            {
                sortName: t("name"),
                sortValue: `name/${locale}`,
                sortIcon: "",
            },
            {
                sortName: t("place"),
                sortValue: "", // COULD: Add sortValue once implemented by discover.swiss
                sortIcon: "",
            },
            {
                sortName: t("date"),
                sortValue: "nextOccurrence",
                sortIcon: "",
            },
        ],
    },
    Tour: {
        component: Tours,
        filterable: true,
        defaultPageSize: 8,
        sortOptions: [
            {
                sortName: t("name"),
                sortValue: `name/${locale}`,
                sortIcon: "",
            },
            {
                sortName: t("place"),
                sortValue: "", // COULD: Add sortValue once implemented by discover.swiss
                sortIcon: "",
            },
            {
                sortName: t("distance"),
                sortValue: "length",
                sortIcon: "",
            },
            {
                sortName: t("duration"),
                sortValue: "time",
                sortIcon: "",
            },
            {
                sortName: t("difficulty"),
                sortValue: "", // COULD: Add sortValue once implemented by discover.swiss
                sortIcon: "",
            },
        ],
    },
    Webcam: {
        component: Webcams,
        filterable: true,
        defaultPageSize: 9,
        sortOptions: [
            {
                sortName: t("name"),
                sortValue: `name/${locale}`,
                sortIcon: "",
            },
            {
                sortName: t("place"),
                sortValue: "", // COULD: Add sortValue once implemented by discover.swiss
                sortIcon: "",
            },
        ],
    },
    Portfolio: {
        component: Portfolios,
        filterable: true,
        defaultPageSize: 9,
        sortOptions: [
            {
                sortName: t("name"),
                sortValue: `name/${locale}`,
                sortIcon: "",
            },
            {
                sortName: t("place"),
                sortValue: "", // COULD: Add sortValue once implemented by discover.swiss
                sortIcon: "",
            },
            ...(false && props.searchTypes?.includes("FoodEstablishment") // currently hard-disabled bc customers changed their mind (for now)
                ? [
                      {
                          sortName: t("priceSegment"),
                          sortValue: "",
                          sortIcon: "",
                      },
                  ]
                : []),
            ...(false && props.searchTypes?.includes("LodgingBusiness") // currently hard-disabled bc customers changed their mind (for now)
                ? [
                      {
                          sortName: t("stars"),
                          sortValue: "",
                          sortIcon: "",
                      },
                  ]
                : []),
        ],
    },
    Product: {
        component: Portfolios,
        filterable: false,
        defaultPageSize: 6,
        sortOptions: [
            {
                sortName: t("name"),
                sortValue: `name/${locale}`,
                sortIcon: "",
            },
        ],
    },
};

const page = ref(1);
const pageSize = ref(getDefaultPageSize(props.type));
const pageCount = ref(0);
function getDefaultPageSize(overviewType: DetailPageType | string): number {
    const fallbackPageSize = 8;
    if (overviewType in overviewComponents) {
        return overviewComponents[overviewType].defaultPageSize;
    }
    return fallbackPageSize;
}

// Perhaps a toggle in the CMS to enable infinite scrolling for certain overview types?
const overviewsWithInfiniteScroll: Array<String> = [
    DetailPageType.Tour,
    "Portfolio",
];
const infiniteScrollEnabled = ref<boolean>();
setupResizeEventListener();

filter().then(() => {
    isDataLoading.value = false;
});

function toggleFilters() {
    showFilters.value = !showFilters.value;

    const hasScrollbar = window.innerWidth > document.body.clientWidth;
    const style = document.body.style;
    const doc = document.documentElement;

    // disable content scrolling while filter menu is open
    if (showFilters.value && hasScrollbar) {
        // get scroll BEFORE position
        const scrollPosition = `${-doc.scrollTop}px`;
        style.position = "fixed";
        style.top = scrollPosition;
        style.overflowY = "scroll";
        style.width = "100%";
    } else {
        style.position = "static";
        style.overflowY = "auto";
        // set scroll AFTER position
        doc.scrollTop = -parseInt(style.top);
    }
}

function updateOrderBy(sortValue: string, sortOrder?: sortOrder) {
    if (!sortValue) return;

    isDataLoading.value = true;
    resetSortingIcons();

    if (sortOrder) {
        setSorting(sortValue, sortOrder);
    } else {
        if (orderBy.value.startsWith(sortValue)) {
            // if already sorting by this sortValue, only change order
            if (orderBy.value.endsWith("asc")) {
                sortOrder = "desc";
            } else {
                [sortValue, sortOrder] = ["", ""];
            }
        } else {
            // if a different sortValue was active, new value show be sorted in ascending order
            sortOrder = "asc";
        }
        // finally update the sorting
        setSorting(sortValue, sortOrder);
    }
    filter().then(() => (isDataLoading.value = false));
}

/** clear all sorting icons (doesn't affect orderBy value) */
function resetSortingIcons(): void {
    const _sortOptions = overviewComponents[props.type].sortOptions;
    if (!_sortOptions) return;
    _sortOptions.forEach((o) => (o.sortIcon = ""));
}

function setSorting(value: string, order: sortOrder): void {
    orderBy.value = `${value} ${order}`.trim();
    updateSortIconByValueAndOrder(value, order);
}

function updateSortIconByValueAndOrder(
    sortValue: string,
    sortOrder: sortOrder = "",
) {
    const _sortOptions = overviewComponents[props.type].sortOptions;
    _sortOptions.find((o) => o.sortValue === sortValue).sortIcon =
        iconBySortOrder(sortOrder) as sortOption["sortIcon"];
}

const iconBySortOrder = (order: sortOrder): sortOption["sortIcon"] =>
    ({
        "": "",
        asc: "fa-sort-up",
        desc: "fa-sort-down",
    })[order] as any;

const getTagFilter = (tags: EntryInterface[]) =>
    tags
        .map((t) => `allTag/any(item: item eq '${t["identifier"]}')`)
        .join(" and ");

function getFilterString() {
    const extraFilters: string[] = [];

    if (props.type === DetailPageType.Event) {
        const [start, end] = dateRange.value;

        const startDate = new Date(start).toISOString();
        const endDate = new Date(end).toISOString();

        extraFilters.push(
            `schedule/any(item: item/startDate gt ${startDate} and item/startDate lt ${endDate})`,
        );

        // Top Events
        if (topEntryToggle.value) {
            const topEventsFilter = getTagFilter(props.topEntryTags);
            extraFilters.push(topEventsFilter);
        }
    } else if (props.type === DetailPageType.Tour) {
        // Top Tours
        if (topEntryToggle.value) {
            const topToursFilter = getTagFilter(props.topEntryTags);
            extraFilters.push(topToursFilter);
        }
        // open/closed state
        if (statusFilter.value !== "all")
            extraFilters.push(`state eq '${statusFilter.value}'`);

        // tour length
        const [minLength, maxLength] = tourLengthRange.value;
        // min tour length unless minimum selected
        if (minLength > tourLengthRangeMin)
            extraFilters.push(`length ge ${minLength * 1000}`);
        // max tour length unless maximum selected
        if (maxLength < tourLengthRangeMax)
            extraFilters.push(`length le ${maxLength * 1000}`);

        // tour duration
        const [minDuration, maxDuration] = tourDurationRange.value;
        // min tour duration unless minimum selected
        if (minDuration > tourDurationRangeMin)
            extraFilters.push(`time ge ${minDuration * 60}`);
        // max tour duration unless maximum selected
        if (maxDuration < tourDurationRangeMax)
            extraFilters.push(`time le ${maxDuration * 60}`);

        // tour ascent
        const [minAscent, maxAscent] = tourAscentRange.value;
        // min tour ascent
        if (minAscent > tourAscentRangeMin)
            extraFilters.push(`elevation/ascent ge ${minAscent}`);
        // max tour ascent unless maximum selected
        if (maxAscent < tourAscentRangeMax)
            extraFilters.push(`elevation/ascent le ${maxAscent}`);

        // best months
        const enabledMonths = bestMonths.value
            .filter((month) => month.enabled)
            .map((month) => month.value);
        if (enabledMonths.length > 0)
            extraFilters.push(
                `season/any(season: ${enabledMonths.map((m) => `season eq '${m}'`).join(" or ")})`,
            );
    } else if (props.type === "Portfolio") {
        if (statusFilter.value !== "all") {
            extraFilters.push(`state eq '${statusFilter.value}'`);
        }

        // min food price
        /*  COULD: Implement once discover.swiss supports filtering for priceRange
            update: or not, because customers changed their mind
            update 2: I think it's doable using facets
            const [minPrice, maxPrice] = foodPriceRange.value;
            extraFilters.push(`priceRange ge ${minPrice}`);
            // max food price unless maximum selected
            if (maxPrice < foodPriceRangeMax)
                extraFilters.push(`priceRange le ${maxPrice}`);
        */

        // min star rating
        /*  COULD: Implement once discover.swiss supports filtering for starRating
            update: or not, because customers changed their mind
            update 2: I think it's doable using facets
            const [minStars, maxStars] = lodgingStarsRange.value;
            extraFilters.push(`starRating/value ge ${minStars}`);
            // max star rating unless maximum selected
            if (maxStars < lodgingStarsRangeMax)
                extraFilters.push(`starrating/value le ${maxStars}`);
        */
    }

    const allFilters = [props.filters, ...extraFilters]
        .filter(Boolean)
        .join(" and ");

    return allFilters;
}

function updatePageSize(size: number) {
    pageSize.value = size;
    page.value = 1;
    filterAndRemoveNonAvailableFacets();
}

/**
 * Choose the number of page slots based on browser width.
 * Currently not responsive, but should set a good default on first load.
 * Updates on page change as well.
 */
function getPageSlots() {
    /** Basically for every 75 pixels greater than 300, add a slot, starting at 4 */
    const widthDelta = window.innerWidth - 300;
    const slotWidth = 75;
    const maxSlots = 10;
    const minSlots = 4;
    const slots = minSlots + Math.floor(widthDelta / slotWidth);
    return Math.min(slots, maxSlots);
}

async function filter(
    keepOldResults = false,
    onlyUpdateActiveFacets = false,
    returnDataInsteadOfSetting = false,
    minLoadingTimeMs = 200,
): Promise<void | FilterResponse> {
    let _fakeLoading = true;
    isDataLoading.value = minLoadingTimeMs > 0;
    setTimeout(() => (_fakeLoading = false), minLoadingTimeMs);

    const filters = getFilterString();
    const response = await axios.post<FilterResponse>("/api/filter", {
        filters,
        orderBy: orderBy.value,
        type: props.searchTypes || props.type,
        activeFacets: activeFacets.value,
        pagination: {
            currentPage: page.value,
            resultsPerPage: pageSize.value,
        },
        project: config.project,
        locale,
    });

    while (_fakeLoading) {
        // buffer for 0.1s if real loading time is too short
        await new Promise((resolve) => setTimeout(resolve, 100));
    }

    isDataLoading.value = false;
    

    // rename tag facet to properties
    const tagFacet = response.data.filters.find(
        (f) => f.filterPropertyName === "tag",
    );
    if (tagFacet) tagFacet.name = t("properties");

    // order addressLocality facet alphabetically
    const localityFacet = response.data.filters.find(
        (f) => f.filterPropertyName === "addressLocality",
    );
    if (localityFacet)
        localityFacet.facets.sort((a, b) => a.label.localeCompare(b.label));

    // step into "sui_root" category
    const categoryFacet = response.data.filters.find(
        (f) => f.filterPropertyName === "categoryTree",
    );
    if (categoryFacet) {
        const sui_rootFacet = categoryFacet.facets.find(
            (f) => f.key === "sui_root",
        );
        if (sui_rootFacet) {
            categoryFacet.facets = sui_rootFacet.children;
        }
    }


    if (returnDataInsteadOfSetting) {
        return response.data;
    }

    applyFilterData(response.data, keepOldResults, onlyUpdateActiveFacets);
}

function applyFilterData(
    filterData: FilterResponse,
    keepOldResults: boolean,
    onlyUpdateActiveFacets: boolean,
) {
    if (keepOldResults) {
        data.value.values.push(...filterData.values);
    } else {
        if (onlyUpdateActiveFacets) {
            data.value.filters = filterData.filters;
            preFilterCount.value = filterData.count;
        } else {
            data.value = filterData;
        }
    }
    pageCount.value = Math.ceil(filterData.count / pageSize.value);
    const minPageValue = 1;
    page.value = Math.max(Math.min(page.value, pageCount.value), minPageValue);
}

async function filterAndRemoveNonAvailableFacets(_minLoadingTimeMs?: number) {
    await filter(false, false, true, 0);
    removeNonAvailableActiveFacets();
    const _data = await filter(false, false, true, _minLoadingTimeMs);
    if (_data) applyFilterData(_data, false, false);
}

function updateCheckedKeys(
    keys: Array<string | number>,
    _option: Array<TreeOption | null>,
    meta: {
        node: TreeOption | null;
        action: "check" | "uncheck";
    },
) {
    activeFacets.value[meta.node?.filterPropertyName as string] =
        keys as string[];

    updateFilters();
}

function removeNonAvailableActiveFacets() {
    Object.keys(activeFacets.value).forEach((key) => {
        const activeFacet = activeFacets.value[key];
        const availableFacets = data.value.flatFacets
            .find((filter) => filter.value.filterPropertyName === key)
            ?.value.values.map((facet) => facet.query);

        if (availableFacets) {
            activeFacets.value[key] = activeFacet.filter((facet) =>
                availableFacets.includes(facet),
            );
        }
    });
}

async function updateFilters() {
    areFiltersLoading.value = true;
    removeNonAvailableActiveFacets();
    await filter(false, true, false, 0);
    areFiltersLoading.value = false;
}

function resetFilters() {
    accessibilityFilter.value = false;
    topEntryToggle.value = false;
    selectedStatusIndex.value = 0;
    statusFilter.value = statusItems[selectedStatusIndex.value].value;
    activeFacets.value = {} as Record<string, Array<string>>;
    tourLengthRange.value = [tourLengthRangeMin, tourLengthRangeMax];
    tourDurationRange.value = [tourDurationRangeMin, tourDurationRangeMax];
    tourAscentRange.value = [tourAscentRangeMin, tourAscentRangeMax];
    bestMonths.value = [
        { label: t("jan"), value: "jan", enabled: false },
        { label: t("feb"), value: "feb", enabled: false },
        { label: t("mar"), value: "mar", enabled: false },
        { label: t("apr"), value: "apr", enabled: false },
        { label: t("may"), value: "may", enabled: false },
        { label: t("jun"), value: "jun", enabled: false },
        { label: t("jul"), value: "jul", enabled: false },
        { label: t("aug"), value: "aug", enabled: false },
        { label: t("sep"), value: "sep", enabled: false },
        { label: t("oct"), value: "oct", enabled: false },
        { label: t("nov"), value: "nov", enabled: false },
        { label: t("dec"), value: "dec", enabled: false },
    ];
    dateRange.value = [Date.now(), Date.now() + thirtyOneDaysInMs];

    updateFilters();
}

function updateStatusRadio(newValue: any) {
    statusFilter.value = newValue;
    selectedStatusIndex.value = statusItems.findIndex(
        (i) => i.value === newValue,
    );
    updateFilters();
}

/** @returns true if window.innerWidth <= 993 */
function isInfiniteScrollEnabled(): boolean {
    // at 993px there's a breakpoint where the content can no longer be displayed on (pretty much) one page
    return window.innerWidth <= 993;
}

function setupResizeEventListener() {
    if (overviewsWithInfiniteScroll.includes(props.type)) {
        infiniteScrollEnabled.value = isInfiniteScrollEnabled();
        window.addEventListener("resize", () => {
            infiniteScrollEnabled.value = isInfiniteScrollEnabled();
        });
    }
}

async function loadMore() {
    page.value = page.value + 1;
    await filter(true);
}

function updateBestMonths(index: number) {
    bestMonths.value[index].enabled = !bestMonths.value[index].enabled;
    updateFilters();
}

function getComponentHeightPx(): number {
    const overviewElement = document.getElementById(
        `overview-component-${props.type}`,
    );
    const defaultHeightPx = 676;
    return overviewElement?.scrollHeight || defaultHeightPx;
}
</script>
