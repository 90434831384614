<template>
    <div class="my-5 border-t border-black">
        <div class="my-5 text-medium-700 md:!text-xl">{{ title }}</div>
        <NTheme>
            <NDatePicker
                v-bind:value="dateRange"
                type="daterange"
                @confirm="$emit('update:dateRange', $event)"
            ></NDatePicker>
        </NTheme>
    </div>
</template>

<script lang="ts" setup>
import NTheme from "../../common/NTheme.vue";
import { NDatePicker } from "naive-ui";

const props = defineProps<{
    title: string;
    dateRange: any;
}>();
</script>
