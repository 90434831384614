<template>
    <div class="border-t last:border-b border-black">
        <NTheme>
            <NCollapse
                arrow-placement="right"
                class="filter-checkbox-collapse"
                @item-header-click="toggleState"
            >
                <template #arrow>
                    <i
                        :class="[
                            config.global.iconStyle,
                            openState ? `${closeIcon} rotate-90` : openIcon,
                            // don't worry if the closed state icon is sideways on localhost.
                            // do worry if it's sideways in preview/production.
                        ]"
                    ></i>
                </template>
                <NCollapseItem :title="title">
                    <div class="relative">
                        <NTree
                            block-line
                            cascade
                            checkable
                            :data="items"
                            @update:checked-keys="updateCheckedKeys"
                            :checked-keys="activeFacets || []"
                            check-strategy="parent"
                            :check-on-click="true"
                            class="h-52 overflow-y-auto text-regular-400 py-2 -mt-2"
                            :render-label="renderLabel"
                        />
                        <div class="absolute bottom-0 left-0 right-0 h-6 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
                        <div class="absolute top-0 left-0 right-0 h-6 bg-gradient-to-b from-white to-transparent pointer-events-none"></div>
                    </div>
                </NCollapseItem>
            </NCollapse>
        </NTheme>
    </div>
</template>

<style lang="scss">
.filter-checkbox-collapse {
    border: none !important;
    padding: 0 !important;
    .n-collapse-item__header-main {
        font-family: var(--text-s2-family);
        font-weight: 700 !important;
        font-size: var(--text-medium-size); /* 1.125rem / 18px */
        @apply my-5 pr-1 leading-7 justify-between;
        @screen md {
            font-size: var(--text-large-size); /* 1.25rem / 20px */
        }
    }
    .n-collapse-item__content-inner {
        padding-top: 0 !important;
    }
}
</style>

<script lang="ts" setup>
import { h } from "vue";
import { NCollapse, NCollapseItem, NTree, TreeOption } from "naive-ui";
import { CONFIG_OPTIONS as config } from "@src/globals";
import NTheme from "@src/components/common/NTheme.vue";

const props = defineProps<{
    title: string;
    items: any[];
    activeFacets?: any[];
}>();
const emit = defineEmits(["update:checked-keys"]);

const closeIcon = "fa-minus";
const openIcon = "fa-plus";
let openState = false;
function toggleState() {
    openState = !openState;
}

function updateCheckedKeys(
    keys: Array<string | number>,
    option: Array<TreeOption | null>,
    meta: {
        node: TreeOption | null;
        action: "check" | "uncheck";
    },
) {
    emit("update:checked-keys", keys, option, meta);
}

function renderLabel(info: {
    option: TreeOption;
    checked: boolean;
    selected: boolean;
}) {
    return h(
        "div",
        {
            class: {
                "flex items-center": true,
            },
        },
        [
            h("span", info.option.label),
            h("span", {
                class: {
                    "ml-auto color-gray-500": true,
                },
                innerHTML: info.option.count.toString(),
            }),
        ],
    );
}
</script>
