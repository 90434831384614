<template>
    <div class="flex flex-col gap-8">
        <EventRow
            v-for="event in data.values"
            :link="event.slug"
            :event="event"
        />
    </div>
</template>

<script lang="ts" setup>
import { FilterResponse } from "@src/pages/api/filter";
import { PropType } from "vue";
import EventRow from "../common/EventRow.vue";

const props = defineProps({
    data: Object as PropType<FilterResponse>,
});
</script>
